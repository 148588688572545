// Data sevice
import Axios from './Axios';
import axios from 'axios'
import authHeader from './auth-header'

const API_URL = process.env.VUE_APP_API_URL;

class EventsService {

    index() {
        return axios.get(API_URL + 'event', { headers: authHeader() })
    }
    orgIndex(id) {
        return axios.get(API_URL + 'event/'+id, { headers: authHeader() })
    }
    store(item) {
        return Axios({
            method : "post",
            url : 'event', 
            data : item
         })
    }
    update(item) {
        return axios.put(API_URL + 'event/'+item.id, item, { headers: authHeader() })
    }
    delete(item) {
        return axios.delete(API_URL + 'event/'+item.id, { headers: authHeader() })
    }
    organizationEvents(data) {
        return Axios({
            method : "GET",
            url : 'organization/'+data.id+'/calendar/' + data.month
        });
    }
}

export default new EventsService()
