<template>
    <div>
      <v-card>
        <v-toolbar flat class="">
          <div class="mt-5" align="left">
            <v-toolbar-title class="info--text text-uppercase font-weight-bold text--darken-1 justify-center">
              <v-icon class="mr-2 mt-n1" color="info">mdi-calendar</v-icon>Calendar
            </v-toolbar-title>
          </div>
        </v-toolbar>
  
        <v-divider></v-divider>
  
        <v-sheet height="64">
          <v-toolbar flat>
            <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday">
              Today
            </v-btn>
  
            <v-spacer></v-spacer>
  
            <v-btn fab text small color="grey darken-2" @click="calendarPrevious" class="ma-2">
              <v-icon>
                mdi-chevron-left
              </v-icon>
            </v-btn>
  
            <v-toolbar-title v-if="$refs.calendar">
              {{ $refs.calendar.title }}
            </v-toolbar-title>
  
            <v-btn fab text small color="grey darken-2" @click="calendarNext" class="ma-2">
              <v-icon>
                mdi-chevron-right
              </v-icon>
            </v-btn>
  
            <v-spacer></v-spacer>
  
            <v-menu bottom right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on">
                  <span>{{ typeToLabel[type] }}</span>
                  <v-icon right>
                    mdi-menu-down
                  </v-icon>
                </v-btn>
              </template>
  
              <v-list>
                <v-list-item @click="type = 'day'">
                  <v-list-item-title>Day</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = 'week'">
                  <v-list-item-title>Week</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = 'month'">
                  <v-list-item-title>Month</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = '4day'">
                  <v-list-item-title>4 days</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-toolbar>
        </v-sheet>
  
        <v-sheet height="450" max-width="100%">
          <v-calendar 
            ref="calendar" 
            v-model="focusDate" 
            color="primary" 
            :weekdays="weekday" 
            :type="type"
            :events="all_events" 
            :event-overlap-mode="mode" 
            :event-overlap-threshold="30" 
            :event-color="getEventColor"
            @change="getEvents" 
            @click:date="viewSelectedDate"
          ></v-calendar>
        </v-sheet>
      </v-card>
    </div>
  </template>
  
  <script>
  import EventsService from '../../services/events.service';
  export default {
    name: "Event",
    data() {
      return {
        type: 'month',
        types: ['month', 'week', 'day', '4day'],
        typeToLabel: {
          month: 'Month',
          week: 'Week',
          day: 'Day',
          '4day': '4 Days',
        },
        mode: 'stack',
        modes: ['stack', 'column'],
        weekday: [0, 1, 2, 3, 4, 5, 6],
        weekdays: [
          { text: 'Sun - Sat', value: [0, 1, 2, 3, 4, 5, 6] },
          { text: 'Mon - Sun', value: [1, 2, 3, 4, 5, 6, 0] },
          { text: 'Mon - Fri', value: [1, 2, 3, 4, 5] },
          { text: 'Mon, Wed, Fri', value: [1, 3, 5] },
        ],
        focusDate: '',
        organization_events: [],
        events: [],
        colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
        names: [],
        all_events: [],
      }
    },
  
    computed: {
      org() {
        return JSON.parse(localStorage.getItem("org"));
      }
    },
  
    created() {
      this.$nextTick(() => {
        this.getAllEvents();
      });
    },
  
    methods: {
  
      calendarPrevious() {
        this.$refs.calendar.prev()
      },
  
      calendarNext() {
        this.$refs.calendar.next()
      },
  
      setToday() {
        this.focusDate = ''
      },
  
      viewSelectedDate({ date }) {
        this.focus = date
        this.type = 'day'
      },
  
      getEvents({ start, end }) {
        const events = []
  
        const min = new Date(`${start.date}T08:00:00`)
        const max = new Date(`${end.date}T16:59:59`)
        const days = (max.getTime() - min.getTime()) / 86400000
        const eventCount = this.rnd(days, days + 20)
  
        for (let i = 0; i < eventCount; i++) {
  
          const allDay = this.rnd(0, 3) === 0
          const firstTimestamp = this.rnd(min.getTime(), max.getTime())
          const first = new Date(firstTimestamp - (firstTimestamp % 900000))
          const secondTimestamp = this.rnd(2, allDay ? 288 : 8) * 900000
          const second = new Date(first.getTime() + secondTimestamp)
  
          events.push({
            name: this.names[this.rnd(0, this.names.length - 1)],
            start: first,
            end: second,
            color: this.colors[this.rnd(0, this.colors.length - 1)],
            timed: !allDay,
          })
        }
  
        this.events = events
      },
  
      getEventColor(event) {
        return event.color
      },
  
      rnd(a, b) {
        return Math.floor((b - a + 1) * Math.random()) + a
      },
  
      getAllEvents() {
        let data = {}
        data.id = this.org.id;
        data.month = new Date().getMonth() + 1;
  
        EventsService.organizationEvents(data).then(
          success => {
            if (success.status == 200) {
  
              let events = success.data.data;
              if (events.length > 0) {
                this.organization_events = success.data.data
                this.processEventData();
              }
            }
          },
          error => {
            console.log(error)
          }
        );
      },
  
      processEventData() {
        this.organization_events.forEach(event => {
  
          let start_time = new Date(`${event.event_date}`)
          let end_time = new Date(`${event.end_at}`)
          // const days = (max.getTime() - min.getTime()) / 86400000
  
          this.all_events.push({
            name: event.title,
            start: start_time,
            end: end_time,
            color: this.colors[this.rnd(0, this.colors.length - 1)],
          });
        });
      }
    },
  }
  </script>