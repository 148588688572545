// Data sevice
import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.VUE_APP_API_URL;

class DashboardService {
    getTotalVisits() {
        return axios.get(API_URL + 'get-total-visits', { headers: authHeader() });
    }
    getTotalBedsVisited() {
        return axios.get(API_URL + 'get-total-beds-count', { headers: authHeader() });
    }
    getGuestsCount() {
        return axios.get(API_URL + 'get-all-guests-count', { headers: authHeader() });
    }
    getHostsCount() {
        return axios.get(API_URL + 'get-all-hosts-count', { headers: authHeader() });
    }
    getTopVisitors() {
        return axios.get(API_URL + 'visits-monthly-count', { headers: authHeader() });
    }
    getTop5Visitors() {
        return axios.get(API_URL + 'top-5-weekly-visitors', { headers: authHeader() });
    }
    getTop5LongestStayingVisitors() {
        return axios.get(API_URL + 'top-5-staying-weekly-visitors', { headers: authHeader() });
    }
    getWardsVisitSummary() {
        return axios.get(API_URL + 'get-wards-visit-summary', { headers: authHeader() });
    }
    getEventsByOrganization() {
        return axios.get(API_URL + 'events-by-organization', { headers: authHeader() });
    }
    getAllGuestsByMonth() {
        return axios.get(API_URL + 'get-guests-by-month', { headers: authHeader() });
    }
}

export default new DashboardService();
