<template>
  <div>
    <v-card outlined class="mt-n3" tile>
      <div v-if="loading">
        <v-toolbar>
          <div class="mt-5" align="left">
            <v-toolbar-title class="info--text text-uppercase font-weight-bold text--darken-1 justify-center ">
              <v-icon class="mr-2 mt-n1" color="info">mdi-view-dashboard
              </v-icon>
              Dashboard
            </v-toolbar-title>
          </div>
        </v-toolbar>

        <v-progress-linear indeterminate color="info"></v-progress-linear>

        <v-divider></v-divider>
        <v-sheet height="450" max-width="100%"></v-sheet>
      </div>

      <SummaryDashboard v-if="!loading && !showEventsCalendar"></SummaryDashboard>
      <Event v-if="!loading && showEventsCalendar"></Event>
      <FacilityMap v-if="showFacilityMap && facility_manager && !loading" v-bind:facilities="user_managed_facilities"></FacilityMap>
    </v-card>
  </div>
</template>

<script>
import FacilityService from '../../services/facilities.service';
import Event from './Event.vue';
import FacilityMap from './FacilityMap.vue';
import SummaryDashboard from './SummaryDashboard.vue';

export default {
  components: {
    Event, 
    FacilityMap,
    SummaryDashboard
  },
  name: "Dashboard",
  data() {
    return {
      loading: true,
      facility_manager: false,
      user_managed_facilities: [],
      showFacilityMap: false
    }
  },
  computed: {
    org() {
      return JSON.parse(localStorage.getItem("org"));
    },
    showEventsCalendar() {
      if (this.org) {
        return false;
      } else {
        return true;
      }
    },
  },
  created() {
    this.$nextTick(() => {
      setTimeout(this.userManagedFacilities, 1500);
    });
  },
  methods: {
    userManagedFacilities() {
      FacilityService.managerFacilities(localStorage.getItem('token')).then(
        success => {
          if (success.status === 200)
            if ((success.data.data).length > 0) {
              this.user_managed_facilities = success.data.data
              this.facility_manager = true;
              this.loading = false;
            }
          this.loading = false;
        },
        (error) => {
          if (error.response.status === 401) {
            this.handleLogout();
          }
        }
      );
    },
    handleLogout() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    },
  }
}
</script>