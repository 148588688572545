<template>
  <div>
    <v-card class="pa-6">
      <v-row v-if="!viewAuditDasboard">
        <v-col xs="12" md="12">
          <v-alert
            outlined
            type="warning"
            prominent
            border="top"
            icon="mdi-alert"
            class="mx-auto"
            transition="scale-transition"
          >
            You don't have sufficient previleges to access this page or resource,
            please contact the system administrator.
          </v-alert>
        </v-col>
      </v-row>

      <v-row v-else>
        <v-row class="mt-1">
          <v-col xs="12" md="12">
            <v-layout wrap="wrap">
              <v-flex v-for="(stat, s) in stats" :key="s" class="mx-2">
                <v-card align="center" link>
                  <v-toolbar>
                    <v-icon large :color="stat.color">
                      {{ stat.icon }}
                    </v-icon>
                    <v-spacer vertical="true"></v-spacer>
                    <v-toolbar-title>
                      {{ stat.number }}
                      <div class="caption font-weight-light">
                        {{ stat.label }}
                      </div>
                    </v-toolbar-title>
                  </v-toolbar>
                  <v-progress-linear
                    :color="stat.color"
                    value="100"
                  ></v-progress-linear>
                </v-card>
              </v-flex>
            </v-layout>
          </v-col>
          <v-divider class="my-1"></v-divider>
          <v-col cols="12" md="6" sm="6">
            <v-card class="ml-2" v-if="bar_chart_is_ready">
              <v-card-text>
                <GChart
                  :settings="{ packages: ['bar'] }"
                  :data="published"
                  :options="publishedOptions"
                  :createChart="(el, google) => new google.charts.Bar(el)"
                />
              </v-card-text>
            </v-card>

            <v-card class="ml-2" v-else>
              <v-card-text>
                <v-row align="center" justify="center" class="text-center">
                  <v-col>
                    <v-progress-circular
                      indeterminate
                      color="error"
                      class="my-3"
                    ></v-progress-circular>
                    <p caption>Total Visits per Month Loading... Please wait</p>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" md="6" sm="6">
            <v-card class="mr-2" v-if="pie_chart_is_ready">
              <v-card-text>
                <GChart
                  type="PieChart"
                  loading="true"
                  :data="tenders_by_category"
                  :options="poChartOptions2"
                />
              </v-card-text>
            </v-card>

            <v-card class="ml-2" v-else>
              <v-card-text>
                <v-row align="center" justify="center" class="text-center">
                  <v-col>
                    <v-progress-circular
                      indeterminate
                      color="error"
                      class="my-3"
                    ></v-progress-circular>
                    <p caption>Ward Visits Summary Loading... Please wait</p>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" md="6" sm="12">
            <v-card class="ml-2" v-if="line_chart_is_ready">
              <v-card-text>
                <GChart
                  :settings="{ packages: ['line'] }"
                  :data="bids"
                  :options="bidsOptions"
                  :createChart="(el, google) => new google.charts.Line(el)"
                  :events="chartEvents"
                />
              </v-card-text>
            </v-card>

            <v-card class="ml-2" v-else>
              <v-card-text>
                <v-row align="center" justify="center" class="text-center">
                  <v-col>
                    <v-progress-circular
                      indeterminate
                      color="error"
                      class="my-3"
                    ></v-progress-circular>
                    <p caption>Event Logs Loading... Please wait</p>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" md="6" sm="6">
            <v-card class="mr-2" v-if="bidders_pie_chart_is_ready">
              <v-card-text>
                <template>
                  <GChart
                    type="PieChart"
                    loading="true"
                    :data="bidders_by_percentage"
                    :options="bidsPieChartOptions"
                  />
                </template>
              </v-card-text>
            </v-card>

            <v-card class="ml-2" v-else>
              <v-card-text>
                <v-row align="center" justify="center" class="text-center">
                  <v-col>
                    <v-progress-circular
                      indeterminate
                      color="error"
                      class="my-3"
                    ></v-progress-circular>
                    <p caption>Checkin/Checkout Percentage Loading... Please wait</p>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" md="6" sm="6">
            <v-card class="ml-2" v-if="top_5_bar_chart_is_ready">
              <v-card-text>
                <GChart
                  :settings="{ packages: ['bar'] }"
                  :data="top5Visitors"
                  :options="top5Options"
                  :createChart="(el, google) => new google.charts.Bar(el)"
                />
              </v-card-text>
            </v-card>

            <v-card class="ml-2" v-else>
              <v-card-text>
                <v-row align="center" justify="center" class="text-center">
                  <v-col>
                    <v-progress-circular
                      indeterminate
                      color="error"
                      class="my-3"
                    ></v-progress-circular>
                    <p caption>Top 5 Visitors Loading... Please wait</p>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" md="6" sm="6">
            <v-card class="ml-2" v-if="top_longest_staying_bar_chart_is_ready">
              <v-card-text>
                <GChart
                  :settings="{ packages: ['bar'] }"
                  :data="topLongestStayingVisitors"
                  :options="topLongestStayingOptions"
                  :createChart="(el, google) => new google.charts.Bar(el)"
                />
              </v-card-text>
            </v-card>

            <v-card class="ml-2" v-else>
              <v-card-text>
                <v-row align="center" justify="center" class="text-center">
                  <v-col>
                    <v-progress-circular
                      indeterminate
                      color="error"
                      class="my-3"
                    ></v-progress-circular>
                    <p caption>Top Longest Staying Visitors Loading... Please wait</p>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import DashboardService from "../../services/dashboard.service";
import UserService from "../../services/user.service";

export default {
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    loading() {
      return this.$store.state.loading;
    },
    user() {
      return this.$store.state.auth.user;
    },
    org() {
      return JSON.parse(localStorage.getItem("org"));
    },
    viewAuditDasboard() {
      if (this.org) {
        return true;
      } else {
        return false;
      }
    },
  },

  created() {
    if (!this.loggedIn) {
      this.$router.push("/login");
    }
    this.getTotalVisits();
    this.getTotalBedsVisited();
    this.getGuestsCount();
    this.getHostsCount();
    this.getTopVisitors();
    this.getWardsVisitSummary();
    this.getEventsByOrganization();
    this.getAllGuestsByMonth();
    this.getTop5Visitors();
    this.getTop5LongestStayingVisitors();
  },

  data() {
    return {
      e1: 0,
      dialog: true,
      pageReady: false,
      bar_chart_is_ready: false,
      top_5_bar_chart_is_ready: false,
      top_longest_staying_bar_chart_is_ready: false,
      pie_chart_is_ready: false,
      bidders_pie_chart_is_ready: false,
      line_chart_is_ready: false,
      column_chart_is_ready: false,
      stats: [
        {
          number: "0",
          label: "Total Visits",
          loading: false,
          description: "10% since last week",
          color: "error",
          icon: "mdi-calendar-text-outline",
          href: "#",
        },
        {
          number: "0",
          label: "Total No. of Hosts",
          loading: false,
          description: "15% since last week",
          color: "green",
          icon: "mdi-bed-outline",
          href: "#",
        },
        {
          number: "0",
          label: "Total No. of Guests",
          loading: false,
          description: "40% of monthly total expected",
          color: "blue",
          icon: "mdi-comment-quote",
          href: "#",
        },
        {
          number: "0",
          label: "Total System Users",
          loading: false,
          description: "5% of total this month",
          color: "grey",
          icon: "mdi-account-multiple",
          href: "#",
        },
      ],
      // Array will be automatically processed with visualization.arrayToDataTable function

      poChartOptions: {
        title: "Purchase Orders",
        is3D: false,
        pieHole: 0.4,
        legend: {
          position: "bottom",
        },
      },
      poChartOptions2: {
        title: "Events Classification",
        subtitle: "All events by classification",
        is3D: false,
        pieHole: 0.4,
        legend: {
          position: "bottom",
        },
      },
      bids: [
        ["Month", "Visits"],
        ["Jan", 0],
        ["Feb", 0],
        ["Mar", 0],
        ["Apr", 0],
        ["May", 0],
        ["Jun", 0],
        ["Jul", 0],
        ["Aug", 0],
        ["Sep", 0],
        ["Oct", 0],
        ["Nov", 0],
        ["Dec", 0],
      ],
      bidsOptions: {
        chart: {
          title: "Checkins/Checkouts",
          subtitle: "All Checkins/Checkouts per Month",
          is3D: false,
          pieHole: 0.4,
        },
      },
      published: [
        ["Month", "Visits"],
        ["Jan", 0],
        ["Feb", 0],
        ["Mar", 0],
        ["Apr", 0],
        ["May", 0],
        ["Jun", 0],
        ["Jul", 0],
        ["Aug", 0],
        ["Sep", 0],
        ["Oct", 0],
        ["Nov", 0],
        ["Dec", 0],
      ],

      top5Visitors: [
        ["Name", "Visits"],
        ["Jane Doe", 0],
        ["John Doe", 0]
      ],

      topLongestStayingVisitors: [
        ["Name", "Time Spent"],
        ["Jane Doe", 0],
        ["John Doe", 0]
      ],

      publishedOptions: {
        chart: {
          title: "Total Visits " + new Date().getFullYear(),
          subtitle: "All recorded visits by month",
          color: "#76A7FA",
          is3D: true,
          pieHole: 0.4,
        },
      },

      top5Options: {
        chart: {
          title: "Top Visitors " + new Date().getFullYear(),
          subtitle: "Top 5 Visitors (Last 7 Days)",
          color: "#8C9EFF",
          is3D: true,
          pieHole: 0.4,
        },
      },

      topLongestStayingOptions: {
        chart: {
          title: "Top Longest Staying Visitors " + new Date().getFullYear(),
          subtitle: "Top Longest Staying Visitors (Last 7 Days)",
          color: "#BOBE65",
          is3D: true,
          pieHole: 0.4,
        },
      },

      chartData: [
        ["Month", "Users"],
        ["Jan", 0],
        ["Feb", 0],
        ["Mar", 0],
        ["Apr", 0],
        ["May", 0],
        ["Jun", 0],
        ["Jul", 0],
        ["Aug", 0],
        ["Sep", 0],
        ["Oct", 0],
        ["Nov", 0],
        ["Dec", 0],
      ],

      chartEvents: {
        ready: () => {
          // do something when chart is first drawn
        },
      },

      usersChartOptions: {
        chart: {
          title: "System Users",
          subtitle: "Bidders, Administrators, and Companies: 2021",
          color: "#97d1fa",
          is3D: false,
          pieHole: 0.4,
        },
      },

      tenders_by_category: [
        ["Visits Category", "Percentage"],
        ["Open Tender", 0],
        ["Prequalification", 0],
        ["Restricted Tender", 0],
        ["Expression of Interest", 0],
        ["Direct Procurement", 0],
        ["Request for Quotation", 0],
        ["Single-stage and two-stage tender", 0],
      ],

      list_of_latest_tenders: [],
      bidders_by_percentage: [
        ["Visits Category", "Percentage"],
        ["Approved", 0],
        ["Pending", 0],
        ["Rejected", 0],
      ],

      monthly_users_by_role: [],

      suppliersChartOptions: {
        chart: {
          title: "Number of Suppliers By Supplier Category",
          subtitle: "Suppliers: 2021",
        },
        bars: "horizontal",
        hAxis: { format: "decimal" },
        height: 1000,
        chartArea: {
          width: "100%",
          height: "100%",
        },
        colors: ["#77ba99", "#ef9e44", "#97d1fa", "#a45f6e", "#d14f57"],
      },

      picker: new Date().toISOString().substr(0, 10),

      bidsPieChartData: [
        ["Visits Category", "Percentage"],
        ["Approved", 0],
        ["Pending", 0],
        ["Rejected", 0],
      ],

      bidsPieChartOptions: {
        title: "Visits Statistics",
        subtitle: "Statistics on All Visits: 2024",
        is3D: false,
        legend: {
          position: "bottom",
        },
      },

      items: [],
    };
  },

  methods: {
    onChartReady() {},
    getCompanyName(company) {
      return company.company_name;
    },

    clarificationPreview(item) {
      this.$router.push("/clarification/" + item.id);
    },

    getEventsByOrganization() {
      return DashboardService.getEventsByOrganization().then(
        (response) => {
          console.log(response);
          if (response.status == 200) {
            let obj = response.data.data;
            console.log(this.tenders_by_category);
            this.tenders_by_category = Object.entries(obj);
            this.pie_chart_is_ready = true;
          } else {
            this.tenders_by_category = [];
            console.log(response);
          }
        },
        (error) => {
          this.items = [];
          console.log(error);
        }
      );
    },

    getTopVisitors() {
      return DashboardService.getTopVisitors().then(
        (response) => {
          if (response.status == 200) {
            this.published = Object.entries(response.data.data);
            this.bar_chart_is_ready = true;
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          this.items = [];
          console.log(error);
        }
      );
    },

    getTop5Visitors() {
      return DashboardService.getTop5Visitors().then(
        (response) => {
          if (response.status == 200) {
            this.top5Visitors = Object.entries(response.data.data);
            this.top_5_bar_chart_is_ready = true;
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          this.items = [];
          console.log(error);
        }
      );
    },

    getTop5LongestStayingVisitors() {
      return DashboardService.getTop5LongestStayingVisitors().then(
        (response) => {
          if (response.status == 200) {
            this.topLongestStayingVisitors = Object.entries(response.data.data);
            this.top_longest_staying_bar_chart_is_ready = true;
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          this.items = [];
          console.log(error);
        }
      );
    },

    getAllTenders() {
      return DashboardService.getAllTenders().then(
        (response) => {
          if (response.status == 200) {
            this.list_of_latest_tenders = response.data.data;
            console.log(this.list_of_latest_tenders);
          } else {
            this.list_of_latest_tenders = [];
            console.log(response.data.error);
          }
        },
        (error) => {
          this.items = [];
          console.log(error);
        }
      );
    },

    tenderPreview(item) {
      this.$router.push("/tender-preview/" + item.id);
    },

    getGuestsCount() {
      return DashboardService.getGuestsCount().then(
        (response) => {
          if (response.status == 200) {
            this.stats[2].number = response.data.data.toString();
            this.stats[2].loading = true;
          } else {
            this.stats[2].number = 0;
            console.log(response);
          }
        },
        (error) => {
          this.items = [];
          console.log(error);
        }
      );
    },

    getHostsCount() {
      return DashboardService.getHostsCount().then(
        (response) => {
          if (response.status == 200) {
            this.stats[3].number = response.data.data.toString();
            this.stats[3].loading = true;
          } else {
            this.stats[3].number = 0;
            console.log(response);
          }
        },
        (error) => {
          this.stats[3].number = [];
          console.log(error);
        }
      );
    },

    getTotalBedsVisited() {
      return DashboardService.getTotalBedsVisited().then(
        (response) => {
          if (response.status == 200) {
            this.stats[1].number = response.data.data.toString();
            this.stats[1].loading = true;
          } else {
            this.stats[1].number = 0;
            console.log(response);
          }
        },
        (error) => {
          this.stats[2].number = [];
          console.log(error);
        }
      );
    },

    getTotalVisits() {
      return DashboardService.getTotalVisits().then(
        (response) => {
          if (response.status == 200) {
            this.stats[0].number = response.data.data.toString();
            this.stats[0].loading = true;
          } else {
            this.stats[0].number = 0;
            console.log(response);
          }
        },
        (error) => {
          this.items = [];
          console.log(error);
        }
      );
    },

    getAllGuestsByMonth() {
      return DashboardService.getAllGuestsByMonth().then(
        (response) => {
          if (response.status == 200) {
            this.bids = Object.entries(response.data.data);
            this.line_chart_is_ready = true;
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          this.bids = [];
          console.log(error);
        }
      );
    },

    getWardsVisitSummary() {
      return DashboardService.getWardsVisitSummary().then(
        (response) => {
          if (response.status == 200) {
            let obj = response.data.data;
            this.bidders_by_percentage = Object.entries(obj);
            this.bidders_pie_chart_is_ready = true;
          } else {
            this.bidders_by_percentage = [];
          }
        },
        (error) => {
          this.items = [];
          console.log(error);
        }
      );
    },

    getAllMonthlyUsersByRole() {
      return UserService.getAllMonthlyUsersByRole().then(
        (response) => {
          console.log(response);
          if (response.status == 200) {
            let obj = response.data.data;
            this.chartData = Object.entries(obj);
            console.log(response.data.data);
            this.column_chart_is_ready = true;
          } else {
            this.bidders_by_percentage = [];
            console.log(response);
          }
        },
        (error) => {
          this.items = [];
          console.log(error);
        }
      );
    },
  },
};
</script>
