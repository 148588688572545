<template>
    <div>
        <v-card>
            <v-toolbar flat class="">
                <div class="mt-5" align="left">

                    <v-toolbar-title class="info--text text-uppercase font-weight-bold text--darken-1justify-center">
                        <v-icon class="mr-2 mt-n1" color="info">mdi-map-marker</v-icon>
                        Facility Locations
                    </v-toolbar-title>

                    <v-divider class="mx-4" vertical></v-divider>

                </div>
            </v-toolbar>

            <gmap-map ref="map" :zoom="zoom" :center="center" style="max-width:100%; min-height:500px;">
                <GmapMarker v-for="(marker, index) in markers"
                    v-bind:position="marker" 
                    v-bind:key="index"
                    @click="center=marker"
                />
            </gmap-map>

        </v-card>
    </div>
</template>
<script>
export default {
    name : "FacilityMap",
    props : [
        'facilities'
    ],

    data() {
        return {
            map : null,
            zoom : 6,
            center : {lat:0, lng:0},
            markers : [],
        }
    },

    created() {
        this.$nextTick(()=> {
            this.locateFacilitiesOnMap();
            this.geolocate();
            console.log(this.markers)
        });
    },
    methods : {
        locateFacilitiesOnMap() {
            if(this.facilities) {
                let allRatios = [];
                this.facilities.forEach(facility => {

                    let data = facility.attributes;

                    this.markers.push({
                        lat : data.latitude,
                        lng : data.longitude,
                        label : data.facility
                    });

                    if(this.markers.length === 1) {
                        this.center = this.markers[0]
                        this.zoom = 8
                    }

                    let dist = this.distanceBetween({"lat" : data.latitude, "lng" : data.longitude})

                    let ratio = 12747/dist

                    console.log(dist, Math.round(ratio))

                    allRatios.push(Math.round(ratio))

                    if(this.markers.length === this.facilities.length && allRatios.length >= 1)
                    {
                        
                        allRatios.forEach(r => {

                            if(r > 30) {
                                this.zoom = 18
                            } else if (r < 1) {
                                this.zoom = 1
                            } else {
                                this.zoom = r
                            }

                        })
                    }
                });
            }
        },
        distanceBetween(target) { 

            let unit = 'Km'
            var rlat1 = Math.PI * this.center.lat / 180
            var rlat2 = Math.PI * target.lat / 180

            var theta = this.center.lng - target.lng
            var rtheta = Math.PI * theta / 180

            var dist = Math.sin(rlat1) * Math.sin(rlat2) + Math.cos(rlat1) * Math.cos(rlat2) * Math.cos(rtheta);
            dist = Math.acos(dist)
            dist = dist * 180 / Math.PI
            dist = dist * 60 * 1.1515

            if (unit === "Km") {
            dist = dist * 1.609344
            }

            return dist
            
        },
        geolocate() {
            navigator.geolocation.getCurrentPosition((position) => {

                if(this.markers.length === 0) {
                    this.center.lat = position.coords.latitude;
                    this.center.lng = position.coords.longitude;
                    this.zoom = 6;
                }
                
                // this.panToMarker();

            });
        },
    }
}
</script>